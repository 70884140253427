import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"

const NotFound = () => (
  <Layout>
    <Head title="404" />
    <h1>404</h1>
    <p>
      You're lost. I'm sorry I led you this way... or if you came here yourself,
      GET OUTTA HERE! (click home above to get back to known land)
    </p>
  </Layout>
)

export default NotFound
